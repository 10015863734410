import './TopMenu.scss';
import {getWindowWidth} from '../../../resources/js/Helpers/Helpers';

const topMenu = () => {
	const handleMenuOpen = () => {
		const menu = document.querySelector('#js-menu-screen');
		const togglers = [...document.getElementsByClassName('js-toggle-menu')];
		
		togglers.forEach(toggle =>
			toggle.addEventListener('click', function () {
				menu.classList.toggle('active');
			})
		);
		
		$(togglers).click(function (event) {
			event.stopPropagation();
		});
	};
	
	const backgroundCloseMenu = (menu) => {
		if (menu.classList.contains('active')) {
			window.addEventListener('click', function () {
				menu.classList.remove('active')
			});
		}
		
		$(menu).click(function (event) {
			event.stopPropagation();
		});
	}
	
	const handleMobileMenu = () => {
		const menuItems = [
			...document.getElementsByClassName('has-submenu'),
		];
		
		const toggleSubmenu = e => {
			e.currentTarget.classList.toggle('active');
			const submenu = e.currentTarget.getElementsByClassName('top-menu__submenu')[0];
			submenu.classList.toggle('active');
		};
		
		menuItems.map(menuItem => menuItem.addEventListener('click', toggleSubmenu));
	};
	
	const handleSearchScreen = () => {
		const search = document.getElementById('js-search-screen');
		const togglers = [...document.getElementsByClassName('js-toggle-search')];
		const menuEle = [...document.getElementsByClassName('js-toggle-menu')];
		
		togglers.map(toggle =>
			toggle.addEventListener('click', function () {
				search.classList.toggle('active');
				
				backgroundCloseMenu(search);
				
				menuEle.map(menu =>
					menu.addEventListener('click', function () {
						search.classList.remove('active')
					})
				);
			})
		);
		
		$(togglers).click(function (event) {
			event.stopPropagation();
		});
	}
	
	handleMenuOpen();
	handleMobileMenu();
	handleSearchScreen();
};

export default topMenu;
